import { createStore } from 'vuex'
import {MUTATION_TYPE,GAME_ID,GAME_MODEL,SKIN_TYPE} from "@/assets/js/config.js"
const getDefaultState=()=>{
	return {
		userInfo:{
			id:"",//用户名
			guid:"",//用户guid
			realCoin:0,//真人币
			esportsCoin:0//竞技币
		},//用户信息
		activeGame:GAME_ID.bac,//当前首页激活的游戏
		tabGame:0,//换台列表中选中的游戏项
		roomList:null,//主台列表（有需要不同游戏类型的台列表则页面或组件中另外添加）
		roomInfo:{},//台信息
		videoLineList:[],//视频线路列表
		currVideoLine:"",//当前视频线路id
		audioSwitch:{
			bgAudio:true,//背景音效开关
			startAudio:true,//开始/结束音效开关
			resultAudio:true,//开注音效
			tenAudio:true,//最后10秒音效
		},//音效开关
		gameList:null,//游戏列表
		gameModel:GAME_MODEL.normal,//游戏模式，默认经典模式
		skin:SKIN_TYPE.normal,//皮肤
		loginModel:null,//登录模式
		currModal:null,//当前显示的模态框
		rechargeFundsList:null,//充值支付方式列表
		rechargeCurrencyList:null,//充值币种列表
		withdrawFundsList:null,//提现提现方式列表
		fundChannel:null,//用户资金渠道
		withdrawLimit:{
			min:null,
			max:null
		},//提现限额
		shareQrStr:"",//推广二维码字符串
		problemList:null,//问题反馈列表
		rechargeLog:null,//充值记录
		withdrawLog:null,//提现记录
		submitLog:null,//提交记录
		isReConnWs:true,//是否重连websocket

		//待处理项
		currAmountList:[[10,20],[50,100],[200]],//当前选中的额度列表
		isFullscreen:"",//当前是否全屏
	}
}
export default createStore({
	state:getDefaultState(),//使用函数是为了方便全部重置
	//state派生状态（如过滤、计数），类似计算属性
	getters: {
		// doneTest(state,getters){
		// 	return state.test.filter(v=>v<3);
		// },
	},
	//提交变更
	mutations: {
		/**
		 * 重置Store
		 */
		[MUTATION_TYPE.resetState](state){
			Object.assign(state,getDefaultState());
		},
		/**
		 * 设置用户信息
		 */
		[MUTATION_TYPE.setUserInfo](state,payload){
			if(!payload.userInfo.guid&&!state.userInfo.guid){
				state.userInfo={
					id:"",
					guid:"",
					realCoin:0,
					esportsCoin:0
				}
			}else{
				Object.assign(state.userInfo,payload.userInfo);
			}
		},
		/**
		 * 设置当前首页激活的游戏
		 */
		[MUTATION_TYPE.setActiveGame](state,payload){
			state.activeGame=payload.activeGame;
		},
		/**
		 * 设置台面信息
		 */
		[MUTATION_TYPE.setRoomInfo](state,payload){
			if(payload.roomInfo){
				Object.assign(state.roomInfo,payload.roomInfo);
			}else{
				state.roomInfo={};
			}
		},
		/**
		 * 设置换台列表中选中的游戏项
		 */
		[MUTATION_TYPE.setTabGame](state,payload){
			state.tabGame=payload.tabGame;
		},
		/**
		 * 设置视频线路列表
		 */
		[MUTATION_TYPE.setVideoLineList](state,payload){
			state.videoLineList=payload.videoLineList;
		},
		/**
		 * 设置当前视频线路ID
		 */
		[MUTATION_TYPE.setCurrVideoLine](state,payload){
			state.currVideoLine=payload.currVideoLine;
		},
		/**
		 * 设置音效开关
		 */
		[MUTATION_TYPE.setAudioSwitch](state,payload){
			Object.assign(state.audioSwitch,payload.audioSwitch);
		},
		/**
		 * 设置游戏列表
		 */
		[MUTATION_TYPE.setGameList](state,payload){
			state.gameList=payload.gameList;
		},
		/**
		 * 设置游戏模式
		 */
		[MUTATION_TYPE.setGameModel](state,payload){
			state.gameModel=payload.gameModel;
		},
		/**
		 * 设置当前网站皮肤
		 */
		[MUTATION_TYPE.setSkin](state,payload){
			state.skin=payload.skin;
		},
		/**
		 * 设置当前用户登录模式
		 */
		[MUTATION_TYPE.setLoginModel](state,payload){
			state.loginModel=payload.loginModel;
		},
		/**
		 * 设置当前选中的额度列表
		 */
		[MUTATION_TYPE.setCurrAmountList](state,payload){
			state.currAmountList=payload.currAmountList;
		},
		/**
		 * 设置当前主台面列表
		 */
		[MUTATION_TYPE.setRoomList](state,payload){
			if(payload.room){//替换单项
				let room=state.roomList.find(v=>v.id==payload.room.id);
				room&&(Object.assign(room,payload.room));
			}else{
				state.roomList=payload.roomList;
			}
			// if(payload.roomList||payload.roomList==null){//替换整个
			// 	state.roomList=payload.roomList;
			// }else{//替换单项
			// 	let room=state.roomList.find(v=>v.id==payload.room.id);
			// 	room&&(Object.assign(room,payload.room));
			// }
		},
		/**
		 * 设置当前显示模态框
		 */
		[MUTATION_TYPE.setCurrModal](state,payload){
			state.currModal=payload.currModal;
		},
		/**
		 * 设置充值支付方式列表
		 */
		[MUTATION_TYPE.setRechargeFundsList](state,payload){
			state.rechargeFundsList=payload.rechargeFundsList;
		},
		/**
		 * 设置充值币种列表
		 */
		[MUTATION_TYPE.setRechargeCurrencyList](state,payload){
			state.rechargeCurrencyList=payload.rechargeCurrencyList;
		},
		/**
		 * 设置提现提现方式列表
		 */
		[MUTATION_TYPE.setWithdrawFundsList](state,payload){
			state.withdrawFundsList=payload.withdrawFundsList;
		},
		/**
		 * 设置提现提现方式列表
		 */
		[MUTATION_TYPE.setFundChannel](state,payload){
			!state.fundChannel&&(state.fundChannel={});
			Object.assign(state.fundChannel,payload.fundChannel);
		},
		/**
		 * 设置提现提现方式列表
		 */
		[MUTATION_TYPE.setWithdrawLimit](state,payload){
			state.withdrawLimit=payload.withdrawLimit;
		},
		/**
		 * 设置推广二维码字符串
		 */
		[MUTATION_TYPE.setShareQrStr](state,payload){
			state.shareQrStr=payload.shareQrStr;
		},
		/**
		 * 设置问题反馈列表
		 */
		[MUTATION_TYPE.setProblemList](state,payload){
			state.problemList=payload.problemList;
		},
		/**
		 * 设置充值记录列表
		 */
		[MUTATION_TYPE.setRechargeLog](state,payload){
			state.rechargeLog=payload.rechargeLog;
		},
		/**
		 * 设置提现记录列表
		 */
		[MUTATION_TYPE.setWithdrawLog](state,payload){
			state.withdrawLog=payload.withdrawLog;
		},
		/**
		 * 设置提交记录列表
		 */
		[MUTATION_TYPE.setSubmitLog](state,payload){
			state.submitLog=payload.submitLog;
		},
		/**
		 * 设置提交记录列表
		 */
		[MUTATION_TYPE.setIsReConnWs](state,payload){
			state.isReConnWs=payload.isReConnWs;
		}
	},

	//
	actions: {
	},
	//模块化
	modules: {
	}
})
